import ScrollAnimations from './ScrollAnimations'
import Pin from './Pin'
import Magnet from './Magnet'
import Cursor from './Cursor'
import Parallax from './Parallax'
import ChangeBg from './ChangeBg'

export default class InitAfterPreloader {
  constructor() {
    this.init()
  }

  init() {
    new ScrollAnimations()
    new Pin()
    new Magnet()
    new Cursor()
    new Parallax()
    new ChangeBg()
  }
}