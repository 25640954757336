import Utils from './Utils'
import gsap from 'gsap'

export default class Cursor {
  constructor() {
    this.cursor = document.querySelector('[data-cursor]')

    this.init()
  }

  init() {
    if(this.cursor) {
      this.initCursor() 
      this.attachListeners()
    }
  }

  initCursor() {
    let pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    let mouse = { x: pos.x, y: pos.y }
    let speed = this.cursor.getAttribute('data-cursor-speed') ?? '0.1'

    let fpms = 60 / 1000
    
    let xSet = gsap.quickSetter(this.cursor, 'x', 'px')
    let ySet = gsap.quickSetter(this.cursor, 'y', 'px')

    gsap.set(this.cursor, {xPercent: -50, yPercent: -50})
    this.cursor.style.pointerEvents = 'none'

    window.addEventListener('mousemove', e => {    
      mouse.x = e.x
      mouse.y = e.y   
    })
    
    gsap.ticker.add((time, deltaTime) => {
      
      let delta = deltaTime * fpms
      let dt = 1.0 - Math.pow(1.0 - speed, delta) 
      
      pos.x += (mouse.x - pos.x) * dt
      pos.y += (mouse.y - pos.y) * dt
      xSet(pos.x)
      ySet(pos.y)
    })
  }

  attachListeners() {
    const buttons = document.querySelectorAll('button')
    const links = document.querySelectorAll('a')

    if(!Utils.isMobile()) {
      buttons.forEach((item, i) => {
        Utils.handleEvent('mouseenter', { 
          onElement: item, 
          withCallback: () => {
            this.cursor.classList.add('is-active')
          }
        })

        Utils.handleEvent('mouseleave', { 
          onElement: item, 
          withCallback: () => {
            this.cursor.classList.remove('is-active')
          }
        })
      })  

      links.forEach((item, i) => {
        Utils.handleEvent('mouseenter', { 
          onElement: item, 
          withCallback: () => {
            this.cursor.classList.add('is-active')
          }
        })

        Utils.handleEvent('mouseleave', { 
          onElement: item, 
          withCallback: () => {
            this.cursor.classList.remove('is-active')
          }
        })
      })        
    }  
  }
}