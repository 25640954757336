import Utils from './Utils'
import gsap from 'gsap'
import { CountUp } from 'countup.js'
import SplitText from '../../gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

export default class ScrollAnimations {
  constructor() {
    this.stagger = 0.1
    this.duration = 1
    this.easing = 'power1.out'
    this.triggerPoint = 'top 100%'
    this.clipPathPrefix = gsap.utils.checkPrefix('clipPath')

    // scroll items
    this.targets = document.querySelectorAll('[data-onscroll]')
    this.targetsOrdered = []

    // counter
    this.counterTargets = document.querySelectorAll('[data-stats-counter]')
    this.counterInstances = []

    // scroll trigger refresh
    this.refreshFn = []
    this.refreshTargets = document.querySelectorAll('[data-scroll-trigger-refresh]')

    this.init()
  }

  init() {
    this._sortTargets()
    this.manageScrollAnimations()

    if(this.counterTargets) {
      this.initCountersToggles()
    }  

    this.addClickListeners()
  }

  addClickListeners() {
    for(let i = 0; i <= this.refreshTargets.length; i++) {
      this.refreshFn[i] = Utils.handleEvent('click', { 
        onElement: this.refreshTargets[i], 
        withCallback: () => {
          setTimeout(() => {
            ScrollTrigger.refresh()
          }, 100)
        }
      })    
    }
  }

  manageScrollAnimations() {    
    this.fadeIn()
    this.fadeInChildren()
    this.fadeInUp()
    this.fadeInUpChildren()
    this.fadeInLeft()
    this.fadeInRight()
    this.fadeInDown()
    this.imageScale()
    this.drawLine()
    this.drawLineRight()
    this.drawLineTop()
    this.drawLineBottom()
    this.splitText()
    this.splitTextSkew()
    this.splitTextEnterRight()
    this.splitTextEnterLeft()
    this.clipPath()
    this.clipPathTop()
    this.toggleClass()
  }

  fadeIn() {
    const fadeInTargets = this.targetsOrdered['fade-in'] ?? false
    if (fadeInTargets.length) {
      ScrollTrigger.batch(fadeInTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { opacity: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }
  }

  fadeInChildren() {
    const fadeInChildrenTargets = this.targetsOrdered['fade-in-children'] ?? false
    if (fadeInChildrenTargets.length) {
      gsap.set(fadeInChildrenTargets, { opacity: 1 })

      ScrollTrigger.batch(fadeInChildrenTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.fromTo(item.querySelectorAll(':scope > *'), {
              opacity: 0
            }, {
              opacity: 1, 
              duration: duration, 
              stagger: this.stagger*1.25,
              ease: easing,
              delay: index*this.stagger + delay
            })
          })  
        }
      })
    }
  }  

  fadeInUp() {
    const fadeInUpTargets = this.targetsOrdered['fade-in-up'] ?? false
    if (fadeInUpTargets.length) {
      gsap.set(fadeInUpTargets, { opacity: 0, y: 20 })

      ScrollTrigger.batch(fadeInUpTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { opacity: 1, y: 0, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })  
        }
      })
    }
  }

  fadeInUpChildren() {
    const fadeInUpChildrenTargets = this.targetsOrdered['fade-in-up-children'] ?? false
    if (fadeInUpChildrenTargets.length) {
      gsap.set(fadeInUpChildrenTargets, { opacity: 1})

      ScrollTrigger.batch(fadeInUpChildrenTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.fromTo(item.querySelectorAll(':scope > *'), {
              opacity: 0, 
              y: 20
            }, {
              opacity: 1, 
              y: 0, 
              duration: duration, 
              stagger: this.stagger*1.25,
              ease: easing,
              delay: index*this.stagger + delay
            })
          })  
        }
      })
    }
  }

  fadeInLeft() {
    const fadeInLeftTargets = this.targetsOrdered['fade-in-left'] ?? false
    if (fadeInLeftTargets.length) {
      gsap.set(fadeInLeftTargets, { opacity: 0, x: -20 })

      ScrollTrigger.batch(fadeInLeftTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { opacity: 1, x: 0, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  fadeInRight() {
    const fadeInRightTargets = this.targetsOrdered['fade-in-right'] ?? false
    if (fadeInRightTargets.length) {
      gsap.set(fadeInRightTargets, { opacity: 0, x: 20 })

      ScrollTrigger.batch(fadeInRightTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { opacity: 1, x: 0, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }  

  fadeInDown() {
    const fadeInDownTargets = this.targetsOrdered['fade-in-down'] ?? false
    if (fadeInDownTargets.length) {
      gsap.set(fadeInDownTargets, { opacity: 0, y: -20 })

      ScrollTrigger.batch(fadeInDownTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { opacity: 1, y: 0, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  imageScale() {
    const imageScaleTargets = this.targetsOrdered['image-scale'] ?? false
    if (imageScaleTargets.length) {
      gsap.set(imageScaleTargets, { opacity: 1 })

      ScrollTrigger.batch(imageScaleTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                image = item.querySelector('img'),
                easing = this._setEasing(item)

            gsap.to(image, {scale: 1, opacity: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  drawLine() {
    const drawLineTargets = this.targetsOrdered['draw-line'] ?? false
    if (drawLineTargets.length) {
      gsap.set(drawLineTargets, { opacity: 1, scaleX: 0, transformOrigin: 'top left' })

      ScrollTrigger.batch(drawLineTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { scaleX: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  drawLineRight() {
    const drawLineRightTargets = this.targetsOrdered['draw-line-right'] ?? false
    if (drawLineRightTargets.length) {
      gsap.set(drawLineRightTargets, { opacity: 1, scaleX: 0, transformOrigin: 'top right' })

      ScrollTrigger.batch(drawLineRightTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { scaleX: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  drawLineTop() {
    const drawLineTopTargets = this.targetsOrdered['draw-line-top'] ?? false
    if (drawLineTopTargets.length) {
      gsap.set(drawLineTopTargets, { opacity: 1, scaleY: 0, transformOrigin: 'top top' })

      ScrollTrigger.batch(drawLineTopTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { scaleY: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  drawLineBottom() {
    const drawLineBottomTargets = this.targetsOrdered['draw-line-bottom'] ?? false
    if (drawLineBottomTargets.length) {
      gsap.set(drawLineBottomTargets, { opacity: 1, scaleY: 0, transformOrigin: 'bottom top' })

      ScrollTrigger.batch(drawLineBottomTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.to(item, { scaleY: 1, duration: duration, ease: easing, delay: index*this.stagger + delay })
          })
        }
      })
    }    
  }

  toggleClass() {
    const toggleClassTargets = this.targetsOrdered['toggle-class'] ?? false
    if (toggleClassTargets.length) {
      toggleClassTargets.forEach((target, index) => {
        ScrollTrigger.create({
          trigger: target,
          id: index+1,
          start: this.triggerPoint,
          onEnter: () => {
            target.classList.add('is-active')
          }
        })
      })     
    }
  }

  splitText() {
    const splitTextTargets = this.targetsOrdered['split-text'] ?? false
    if (splitTextTargets.length) {
      ScrollTrigger.batch(splitTextTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          let tl = []

          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            tl[index] = gsap.timeline()

            let split = new SplitText(item, { 
              type: 'lines,words,chars',
              linesClass: 'split-line'
            })

            tl[index]
              .set(item, { opacity: 1 })
              .fromTo(split.chars, 
                { y: 30, opacity: 0 },
                { y: 0, opacity: 1, duration: duration/2, stagger: 0.01, ease: easing, delay: index*this.stagger + delay }
            )      
          })
        }
      })      
    }       
  }
  
  splitTextSkew() {
    const splitTextSkewTargets = this.targetsOrdered['split-text-skew'] ?? false
    if (splitTextSkewTargets.length) {
      ScrollTrigger.batch(splitTextSkewTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          let tl = []

          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            tl[index] = gsap.timeline()

            let split = new SplitText(item, { 
              type: 'lines,words,chars',
              linesClass: 'split-line'
            })

            tl[index]
              .set(item, { opacity: 1 })
              .fromTo(split.chars, 
                { x: 5, skewX: -10, opacity: 0 },
                { x: 0, skewX:0, opacity: 1, duration: duration/2, stagger: 0.02, ease: easing, delay: index*this.stagger + delay }
            )      
          })
        }
      })      
    }       
  }

  splitTextEnterRight() {
    const splitTextEnterRightTargets = this.targetsOrdered['split-text-enter-right'] ?? false
    if (splitTextEnterRightTargets.length) {
      ScrollTrigger.batch(splitTextEnterRightTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          let tl = []

          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            tl[index] = gsap.timeline()

            let split = new SplitText(item, { 
              type: 'lines,words,chars',
              linesClass: 'split-line'
            })

            tl[index]
              .set(item, { opacity: 1 })
              .fromTo(split.chars, 
                { scaleX: 4, x: 200, opacity: 0, transformOrigin: 'top left' },
                { scaleX: 1, x: 0, opacity: 1, duration: duration, stagger: 0.1, ease: easing, delay: index*this.stagger + delay }
            )      
          })
        }
      })      
    }       
  }

  splitTextEnterLeft() {
    const splitTextEnterLeftTargets = this.targetsOrdered['split-text-enter-left'] ?? false
    if (splitTextEnterLeftTargets.length) {
      ScrollTrigger.batch(splitTextEnterLeftTargets, {
        start: this.triggerPoint,
        once: true,
        onEnter: batch => {
          let tl = []

          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            tl[index] = gsap.timeline()

            let split = new SplitText(item, { 
              type: 'lines,words,chars',
              linesClass: 'split-line'
            })

            tl[index]
              .set(item, { opacity: 1 })
              .set(item.querySelectorAll('.split-line'), { overflow: 'visible' })
              .fromTo(split.chars, 
                { scaleX: 4, x: -200, opacity: 0, transformOrigin: 'top right' },
                { scaleX: 1, x: 0, opacity: 1, duration: duration, stagger: -0.1, ease: easing, delay: index*this.stagger + delay }
            )      
          })
        }
      })      
    }       
  }

  initCountersToggles() {
    if(this.counterTargets.length) {
      ScrollTrigger.batch(this.counterTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let number = item.getAttribute('data-stats-counter')
            let counterInstance = new CountUp(item, number, {
              duration: 3
            })

            item.style.opacity = 1
            counterInstance.start()
          })
        },
        once: true
      })
    }
  }  

  clipPath() {
    const clipPathTargets = this.targetsOrdered['clip-path'] ?? false
    if (clipPathTargets.length) {
      const startProps = {}
      startProps[this.clipPathPrefix] = 'polygon(0 0, 0 0, 0 100%, 0% 100%)'

      const toProps = {}
      toProps[this.clipPathPrefix] = 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'

      gsap.set(clipPathTargets, { opacity: 1})

      ScrollTrigger.batch(clipPathTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.fromTo(item, {
              ...startProps
            }, {
              ...toProps, 
              duration: duration, 
              stagger: this.stagger*1.25,
              ease: easing, 
              delay: index*this.stagger + delay
            })
          })  
        }
      })
    }
  }

  clipPathTop() {
    const clipPathTopTargets = this.targetsOrdered['clip-path-top'] ?? false
    if (clipPathTopTargets.length) {
      const startProps = {}
      startProps[this.clipPathPrefix] = 'polygon(0 0, 100% 0, 100% 0, 0 0)'

      const toProps = {}
      toProps[this.clipPathPrefix] = 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'

      gsap.set(clipPathTopTargets, { opacity: 1})

      ScrollTrigger.batch(clipPathTopTargets, {
        start: this.triggerPoint,
        onEnter: batch => {
          batch.forEach((item, index) => { 
            let delay = this._setDelay(item),
                duration = this._setDuration(item),
                easing = this._setEasing(item)

            gsap.fromTo(item, {
              ...startProps
            }, {
              ...toProps, 
              duration: duration, 
              stagger: this.stagger*1.25,
              ease: easing, 
              delay: index*this.stagger + delay
            })
          })  
        }
      })
    }
  }

  _sortTargets() {
    let targetsIndexes = []

    // build array of indexes
    this.targets.forEach((item, index) => { 
      let animationName = item.getAttribute('data-onscroll')
      targetsIndexes[animationName] = 0
      this.targetsOrdered[animationName] = []
    })    

    // build reordered array
    this.targets.forEach((item, index) => { 
      let animationName = item.getAttribute('data-onscroll')

      this.targetsOrdered[animationName][targetsIndexes[animationName]] = item
      targetsIndexes[animationName] += 1
    })
  }

  _setDelay(item) {
    let delay = 0

    if(!Utils.isMobile()) {
      if(item.hasAttribute('data-delay')) {
        delay = parseFloat(item.getAttribute('data-delay'))
      }
    } else {
      if(item.hasAttribute('data-delay-mobile')) {
        delay = parseFloat(item.getAttribute('data-delay-mobile'))
      } else if(item.hasAttribute('data-delay')) {
        delay = parseFloat(item.getAttribute('data-delay'))
      }
    }

    if(sessionStorage.getItem('firstLoad')) {
      if(item.hasAttribute('data-delay-first-load')) {
        delay = parseFloat(item.getAttribute('data-delay-first-load'))
      }     
    }

    return delay
  }

  _setDuration(item) {
    let duration = this.duration

    if(item.hasAttribute('data-duration')) {
      duration = parseFloat(item.getAttribute('data-duration'))
    }

    return duration    
  }

  _setEasing(item) {
    let easing = this.easing

    if(item.hasAttribute('data-easing')) {
      easing = item.getAttribute('data-easing')
    }

    return easing        
  }
}