import Headroom from 'headroom.js'

export default class HeaderScroll {
	constructor() {
		this.headroomInstance = null
		this.headerTarget = document.querySelector('[data-header-scroll]')

		this.init()
	}

	init() {
    if(this.headerTarget) {
      this.initHeaderoom()
    }
	}

  initHeaderoom() {
		this.headroomInstance = new Headroom(this.headerTarget, {
      'offset': this.headerTarget.getAttribute('data-header-scroll-offset') ?? 300,
      'tolerance': 5,
      'classes' : {
        // when element is initialised
        initial : "header--init",
        // when scrolling up
        pinned : "header--pinned",
        // when scrolling down
        unpinned : "header--unpinned",
        // when above offset
        top : "header--top",
        // when below offset
        notTop : "header--not-top",
        // when at bottom of scroll area
        bottom : "header--bottom",
        // when not at bottom of scroll area
        notBottom : "header--not-bottom",
        // when frozen method has been called
        frozen: "header--frozen"
      }
		})

		this.headroomInstance.init()    
  }
}
