export default class Utils {
  static isMobile() {
    return navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/)
  }

  static offset(el) {
    let rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  static handleEvent(eventName, {
    onElement,
    withCallback,
    useCapture = false
  } = {}, thisArg) {
    const element = onElement || document.documentElement;
  
    function handler(event) {
      if (typeof withCallback === 'function') {
        withCallback.call(thisArg, event);
      }
    }
  
    handler.destroy = function destroy() {
      return element.removeEventListener(eventName, handler, useCapture);
    };
  
    element.addEventListener(eventName, handler, useCapture);
    return handler;
  }

  static throttle = (callback, delay) => {
    let isThrottled = false, args, context;

    function wrapper() {
      if (isThrottled) {
        args = arguments;
        context = this;
        return;
      }

      isThrottled = true;
      callback.apply(this, arguments);

      setTimeout(() => {
        isThrottled = false;
        if (args) {
          wrapper.apply(context, args);
          args = context = null;
        }
      }, delay);
    }

    return wrapper;
  }
}
