import MagnetMouse from 'magnet-mouse'

export default class Magnet {
  constructor() {
    this.magnetTargets = '[data-magnet]'
    this.magnetInstance = null

    this.init()
  }

  init() {
    this.magnetInstance = new MagnetMouse({
      magnet: {
        element: this.magnetTargets,
        class: 'is-magnet-hover',
        distance: 10
      }
    })
      
    this.magnetInstance.init()
    window.dispatchEvent(new Event('resize'))
  }
}