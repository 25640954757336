import ScrollAnimations from './ScrollAnimations'
import InitAfterPreloader from './InitAfterPreloader'

export default class Preloader {
  constructor() {
    //this.loader = document.querySelector('.c-load')

    this.init()
  }

  init() {
    this.animate()
  }

  animate() {
    setTimeout(() => {
      document.documentElement.classList.add('is-loaded')
    }, 1) //500

    setTimeout(() => {
      new ScrollAnimations()
      new InitAfterPreloader()
    }, 1) //1100
  }
}