import Utils from './Utils'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollSmoother from '../../gsap/ScrollSmoother'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

export default class Parallax {
  constructor() {
    this.wrapper = '[data-smoothscroller-wrapper]'
    this.content = '[data-smoothscroller-content]'

    this.smoothscroller = null
    this.init()
  }

  init() {
    if(document.querySelector(this.wrapper) && document.querySelector(this.content)) {
      this.initSmoothScroller()
    }
  }

  initSmoothScroller() {
    this.smoothscroller = ScrollSmoother.create({
      wrapper: this.wrapper,
      content: this.content,
      smooth: 1,
      smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices),
      normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
      effects: true,
      preventDefault: true
    })   
  }
}
