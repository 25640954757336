import Utils from './Utils'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class ChangeBg {
  constructor() {
    this.changeBgTargets = document.querySelectorAll('[data-change-bg-wrapper]')

    this.init()
  }

  init() {
    if(this.changeBgTargets) {
      this.initChangeBg()
    }
  }

  initChangeBg() {

    this.changeBgTargets.forEach((wrapper, index) => {
      const targets = wrapper.querySelectorAll('[data-change-bg]')
      const colors = []

      targets.forEach((item, i) => {
        const target = wrapper
        colors[i] = item.dataset.changeBg

        if(i == 0) {
          wrapper.style.backgroundColor = colors[i]
        }
  
        ScrollTrigger.create({
          trigger: item,
          start: 'top 50%',
          onEnter: () => {
            gsap.to(target, { duration: 0.6, backgroundColor: colors[i] })
          },
          onLeaveBack: () => {
            if(i > 0) {
              gsap.to(target, { duration: 0.6, backgroundColor: colors[i-1] })
            } else {
              gsap.to(target, { duration: 0.6, backgroundColor: colors[0] })     
            }
          }
        })
      })

    })

  }
}
