// import 'lazysizes'
// import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import Preloader from './global/Preloader'
import HeaderScroll from './global/HeaderScroll'

//document.addEventListener('DOMContentLoaded', () => {
//   window.onbeforeunload = () => {
//     window.scrollTo(0,0)
//     document.documentElement.scrollTop = 0
//     document.body.scrollTop = 0
//   }  

//window.addEventListener('DOMContentLoaded', () => {
document.addEventListener('DOMContentLoaded', () => {  
  if(!document.querySelector('.w-password-page') && !document.querySelector('.w-editor') && !document.querySelector('.w-editor-body')) {
    new Preloader()
    new HeaderScroll()
  } else {
    document.documentElement.classList.add('is-loaded')
    document.documentElement.classList.add('is-no-animations')
  }
})
