import Utils from './Utils'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class Pin {
  constructor() {
    // pinned sections
    this.sectionPinTargets = document.querySelectorAll('[data-section-pin-panel]')
    this.sectionPinScrollTriggers = []
    this.resizePinFn = null
    this.windowHeight = window.innerHeight

    // configurable pinned items
    this.sectionConfigurablePinTargets = document.querySelectorAll('[data-item-pin]')

    this._resizeCb = () => this._onResize()

    this.init()
  }
  
  init() {
    if(this.sectionPinTargets) {
      ScrollTrigger.addEventListener('refreshInit', this._resizeCb, { passive: true })
      this.initSectionPinPanels()
    }

    if(this.sectionConfigurablePinTargets) {
      this.initConfigurablePin()
    }    
  }


  initSectionPinPanels() {
    this.sectionPinTargets.forEach((panel, i) => {
      const panelOffset = Utils.offset(panel),
            panelBottom = panelOffset.top + panel.clientHeight,
            startTrigger = panelBottom - this.windowHeight

      this.sectionPinScrollTriggers[i] = ScrollTrigger.create({
        trigger: panel,
        start: startTrigger,
        pin: true, 
        pinSpacing: false,
        scrub: true
      })
    })
  }

  initConfigurablePin() {
    this.sectionConfigurablePinTargets.forEach((item, i) => {
      const trigger = item.getAttribute('data-pin-trigger') ?? item,
            start = item.getAttribute('data-pin-start') ?? 'top top',
            end = item.getAttribute('data-pin-end') ?? 'bottom 0px' 

      ScrollTrigger.create({
        trigger: trigger,
        start: start,
        end: end,
        pin: true, 
        pinSpacing: false
      })
    })    
  }

  _onResize() {
    this.windowHeight = window.innerHeight
  
    for(let i = 0; i < this.sectionPinScrollTriggers.length; i++) {
      this.sectionPinScrollTriggers[i].kill()
    }

    this.initSectionPinPanels()
  }  
}